import api from '@/libs/axios'

export default {
  async create(payload) {
    const response = await api.post('/admin/daily-missions', payload)
    return response.data.data.new_document || null
  },
  async update(payload) {
    return await api.put('/admin/daily-missions', payload)
  },
  async delete(payload) {
    return await api.delete('/admin/daily-missions', {
      data: payload,
    })
  },
  async getList(params) {
    return await api.get('/admin/daily-missions', {
      params,
    })
  },
  async get(params) {
    let response = await api.get('/admin/daily-missions/detail', {
      params,
    })
    return response.data.data.document || null
  },
  async updateDisplayOrder(payload) {
    return await api.post('/admin/daily-missions/update-display-order', payload)
  }
}
