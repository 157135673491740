<template>
  <div>
    <b-table-simple caption-top responsive bordered>
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">STT</b-th>
          <b-th class="text-center">Mission Name</b-th>
          <b-th class="text-center">Mission Key</b-th>
          <b-th class="text-center">Reward</b-th>
          <b-th class="text-center">Action</b-th>
        </b-tr>
      </b-thead>
      <draggable v-model="list" tag="tbody">
        <b-tr v-for="(item, index) in list" :key="item._id || index">
          <b-td scope="row" class="text-center">{{ item.display_order + 1 }}</b-td>
          <b-td class="text-center">
            <render-translatable-text :component-data="item.name" />
          </b-td>
          <b-td class="text-center">
            {{ item.mission_key }}
          </b-td>
          <b-td class="text-center">{{ item.rewards[0].type }}: {{ item.rewards[0].amount }}</b-td>
          <b-td class="text-center">
            <b-button
              variant="gradient-warning"
              style="margin-right: 5px"
              class="btn-icon"
              @click="editDailyMission(item._id)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              @click="confirmDelete(item._id)"
              variant="gradient-danger"
              class="btn-icon"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>
  </div>
</template>
<script>
import service from "../../service";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  props: {
    daily_missions: { type: Array, required: true },
  },
  data() {
    return {
      list: this.daily_missions.length ? daily_missions : [],
    };
  },
  watch: {
    daily_missions() {
      this.list = this.daily_missions;
    },
    list(value) {
      this.$emit("changeMission", value);
    },
  },
  methods: {
    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteMission(id);
          }
        });
    },
    async deleteMission(id) {
      await service.delete({ id: id });
      this.$emit("getList");
    },
    editDailyMission(id) {
      this.$router.push({
        name: "mission-daily-detail",
        params: { daily_id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sortable-chosen {
  background-color: bisque;
}
</style>
