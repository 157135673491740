<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Daily Mission" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <div class="d-flex justify-content-between">
          <h2 class="mb-2">Daily Mission</h2>
          <div>
            <loading-button
              icon="CheckCircleIcon"
              variant="warning"
              size="md"
              :disabled="!is_mission_changed"
              @click="updateDisplayOrder"
            >
              Update Display Mission
            </loading-button>
            <loading-button
              class="ml-1"
              icon="PlusIcon"
              variant="primary"
              size="md"
              @click="openCreateDailyMissionModal"
            >
              Add
            </loading-button>
          </div>
        </div>
      </section>
    </b-media>
    <b-card>
      <mission-daily-table
        :daily_missions="daily_missions"
        @getList="getList"
        @changeMission="changeMission"
      />
    </b-card>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_per_page"
    />
    <mission-daily-form-modal ref="dailyMissionFormModal" @loadData="getList" />
  </div>
</template>

<script>
import service from "../service";
import MissionDailyTable from "./_components/MissionDailyTable.vue";
import MissionDailyFormModal from "./_components/MissionDailyFormModal.vue";
import draggable from "vuedraggable";

export default {
  components: {
    MissionDailyTable,
    MissionDailyFormModal,
    draggable,
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_per_page: 50,
      daily_missions: [],
      editLanguage: null,
      is_mission_changed: false,
    };
  },

  watch: {
    daily_missions(value, old_value) {
      if (!old_value.length) {
        return;
      }
      if (this.is_mission_changed === false) {
        this.is_mission_changed = true;
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_per_page,
      });
      if (response.data.type === "DATA") {
        this.daily_missions = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },

    changeMission(value) {
      this.daily_missions = value;
    },

    async updateDisplayOrder() {
      const res = await service.updateDisplayOrder({
        list: this.daily_missions,
      });
      if (res.data && res.data.type === "SUCCESS") {
        await this.getList();
      }
    },

    goToCreateDailyMission() {
      this.$router.push({
        name: "mission-daily-create",
      });
    },

    openCreateDailyMissionModal() {
      this.$refs.dailyMissionFormModal.openCreateModal();
    },
  },
};
</script>
