<template>
  <div v-if="isOpen">
    <b-modal
      centered
      :title="isUpdate ? 'Update Daily Mission' : 'Create Daily Mission'"
      v-model="isOpen"
      id="modal-xl"
      size="xl"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <validation-observer
          ref="createForm"
          v-slot="{ handleSubmit }"
          class="mr-4 ml-4"
        >
          <b-form @submit.stop.prevent="handleSubmit()">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Mission Name"
                  label-for="h-mission-name"
                  label-cols-md="3"
                >
                  <ui-component :component-data="model.name" class="border" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Mission Description"
                  label-for="h-mission-description"
                  label-cols-md="3"
                >
                  <ui-component
                    :component-data="model.description"
                    class="border"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <validation-provider
              name="Mission key"
              v-slot="validationContext"
              :rules="{ required: true }"
            >
              <b-row class="pb-2">
                <b-col md="3">
                  <label>Mission key</label>
                </b-col>
                <b-col md="9">
                  <b-form-input
                    v-model="model.mission_key"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback id="input-category-name-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-col>
              </b-row>
            </validation-provider>

            <div v-for="(item, index) in model.rewards" :key="index">
              <validation-provider
                name="Reward type"
                v-slot="validationContext"
                :rules="{ required: true }"
              >
                <b-row class="pb-2">
                  <b-col md="3">
                    <label>Reward type</label>
                  </b-col>
                  <b-col md="9">
                    <b-form-select
                      v-model="item.type"
                      :options="missionRewards"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      id="input-category-name-feedback"
                      >{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </validation-provider>

              <validation-provider
                name="Reward amount"
                v-slot="validationContext"
                :rules="{ required: true }"
              >
                <b-row class="pb-2">
                  <b-col md="3">
                    <label>Reward amount</label>
                  </b-col>
                  <b-col md="9">
                    <b-form-input
                      v-model="item.amount"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      id="input-category-name-feedback"
                      >{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </validation-provider>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button
          :disabled="!!isLoading"
          type="submit"
          variant="primary"
          @click="onSave"
        >
          <b-spinner v-if="isLoading" class="mr-1" small />
          <span>Save</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";
import missionRewardService from "./../../../mission_reward/service";
import defaultObject from "./../../defaultObject.js";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import { categories, selectRewards } from './../../constants'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isOpen: false,
      isUpdate: false,
      isLoading: false,
      model: {},
      missionRewards: [],
      categories,
    };
  },
  async created() {
    this.getMissionRewards();
  },
  methods: {
    async getMissionRewards() {
      const { data } = await missionRewardService.getAll()
      if (data && data.data.list && data.data.list.length) {
        this.missionRewards = data.data.list.map(reward => {
          return {
            text: reward.type,
            value: reward.type,
          }
        })
        return
      }

      this.missionRewards = selectRewards
    },

    openCreateModal() {
      this.model = JSON.parse(JSON.stringify(defaultObject));
      this.isOpen = true;
      this.isUpdate = false;
    },

    async openUpdateModal(dailyMission) {
      this.model = dailyMission;
      this.isOpen = true;
      this.isUpdate = true;
    },

    async onSave() {
      let validation = await this.$refs.createForm.validate();
      if (validation) {
        this.isOpen = true;
        this.isLoading = true;
        if (!this.isUpdate) {
          try {
            let response = await service.create(this.model);
            if (response.data.type === "DATA") {
              this.$store.dispatch("pushSuccessNotify", {
                text: "Daily mission created!",
              });
            } else {
              this.$store.dispatch("pushErrorNotify", {
                text: "Error created daily mission!",
              });
            }
          } catch (e) {
            this.isLoading = false;
          }
        } else {
          try {
            await service.update({ data: JSON.stringify(this.model) });
          } catch (e) {
            this.isLoading = false;
          }
        }
        this.isLoading = false;
        this.isOpen = false;
        this.$emit("loadData");
      }
    },

    cancel() {
      this.isOpen = false;
      this.isUpdate = false;
      this.model = JSON.parse(JSON.stringify(defaultObject));
      this.$emit("loadData");
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  font-size: 16px;
  margin-top: 0.3rem;
  margin-bottom: 0;
}
</style>
