module.exports = {
    categories: [
        {
            text: "---",
            value: "",
        },
        {
            text: "Arena",
            value: "arena",
        },
        {
            text: "Pronun",
            value: "pronun",
        },
        {
            text: "Breaking",
            value: "breaking",
        },
    ],
    selectRewards: [
        {
            text: "Gem",
            value: "GEM",
        },
        {
            text: "Token",
            value: "TOKEN",
        },
    ]
}
