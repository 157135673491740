export default {
  name: {
    type: 'translatable-text',
    value: null,
    meta: {
      default_lang: 'en',
    },
    translations: [{ language: 'vi', value: '' }],
  },
  description: {
    type: 'translatable-text',
    value: null,
    meta: {
      default_lang: 'en',
    },
    translations: [{ language: 'vi', value: '' }],
  },
  rewards: [
    {
      type: 'GEM',
      amount: 0
    }
  ]
}
